import React from "react"
import { StaticQuery, graphql } from "gatsby"

import Item from "../components/Item"
import Layout from "../components/Layout"
import SEO from "../components/SEO"

import colors from "../utils/colors"
import "../utils/image"

export const query = graphql`
  query {
    pature: file(relativePath: { eq: "chevres/C2.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    chevre: file(relativePath: { eq: "chevres/C1.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

const Home = () => {
  const description = `Nous fabriquons dans notre ferme 10 fromages de chèvre depuis 1991 avec le lait de nos 140 chèvres alpines nourries avec l’herbe et les céréales produites sur les 28 ha de l’exploitation.`

  return (
    <Layout>
      <SEO title="Accueil" description={description} />
      <div style={{ textAlign: "center", marginBottom: "1.8rem" }}>
        <h1
          style={{
            textTransform: "uppercase",
            marginBottom: "0.9rem",
          }}
        >
          Bienvenue chez Eric & Maryvonne BOISBRAS
        </h1>
        <h2 style={{ color: colors.green }}>
          Fromages de chèvre fermiers depuis 1991
        </h2>
        <p style={{ textAlign: "justify" }}>{description}</p>
      </div>
      <StaticQuery
        query={query}
        render={({ chevre, pature }) => (
          <>
            <Item
              image={pature}
              title="L'installation"
              text={
                <p>
                  Nous avons démarré notre activité d’élevage de chèvre
                  laitières en 1987. Nous avons commencé à fabriquer du fromage
                  de chèvre trois ans plus tard, en 1991 avec une trentaine de
                  chèvres. De fil en aiguille, le troupeau s’est agrandi jusqu’à
                  atteindre 140 chèvres en moyenne sur l’exploitation.
                </p>
              }
            ></Item>
            <Item
              image={chevre}
              title="Le passage en agriculture biologique"
              text={
                <p>
                  En 1999, nous décidons de convertir l’exploitation en
                  agriculture biologique. Cette décision a été motivée par le
                  refus d’utiliser des engrais de synthèse et des produits
                  phytopharmaceutiques. Ce choix n’a pas révolutionné nos
                  pratiques agricoles et fromagères car avant la conversion, nos
                  pratiques étaient proches des exigences du cahier des charges
                  de l’agriculture biologique.
                </p>
              }
              left
            ></Item>
          </>
        )}
      />
    </Layout>
  )
}

export default Home
